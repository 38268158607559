import { tns } from 'tiny-slider/src/tiny-slider';
const gallery = document.querySelector('#main-gallery');
if (gallery) {
  var slider = tns({
    container: gallery,
    items: 1,
    slideBy: "page",
    autoplay: true,
    autoWidth: true,
    arrowKeys: false,
    rewind: true,
    nav: false,
    controls: true,
    controlsText: [
      '<span class="sr-only">Previous Image</span><img src="/images/arrow-left-gallery.svg" aria-hidden="true" class="gallery-arrow">',
      '<span class="sr-only">Next Image</span><img src="/images/arrow-right-gallery.svg" aria-hidden="true" class="gallery-arrow">'
    ],
    autoplayText: [
      '<span><img src="/images/play-gallery.svg" aria-hidden="true" class="autoplay-play"></span>',
      '<span><img src="/images/pause-gallery.svg" aria-hidden="true" class="autoplay-pause"></span>'
    ],
    onInit: focusControls()
  });
}

function focusControls() {

  setTimeout(function () {
    let controls = document.querySelector('.tns-controls');

    controls.firstElementChild.setAttribute('tabindex', 0);
    controls.lastElementChild.setAttribute('tabindex', 0);
    controls.removeAttribute('tabindex');
  }, 1000);
}